<template>

    <div class="loader" v-if="loading" v-loading="loading"></div>

    <teleport to="head">
        <title>{{ txt[prefs.lang].reviews }} PLANTA</title>
    <!--        <meta name="description" :content=product[prefs.lang].meta_description>-->
    <!--        <meta name="keywords" :content=product[prefs.lang].meta_keyword>-->
    </teleport>

    <teleport to="#top_benefit">
        <div class="benefit_area ba_rev">
            <h1>{{ txt[prefs.lang].reviews }}</h1>
            <h2>{{ txt[prefs.lang].review_desc }}</h2>
        </div>
    </teleport>

    <section v-if="reviews" class="reviews">
<!--        <p v-if="!me">-->
<!--            {{ txt[prefs.lang].pls_login }} {{ txt[prefs.lang].to_review }}: <el-button @click="$emit('showLogin')" size="mini" plain round>{{ txt[prefs.lang].login }}</el-button>-->
<!--        </p>-->

        <p v-for="r in reviews">
            <b>{{ r.author }}</b>:<br>
            {{ r.text }}<br>
            <span v-if="r.product && product(r.product.id)">
                <router-link :to="'/product/'+product(r.product.id).url">{{ product(r.product.id)[prefs.lang].name }}</router-link>,
            </span>
            {{ txt[prefs.lang].rating }}: <b>{{ r.rating }}</b>
        </p>
    </section>

</template>

<script>
import useCart from "@/use/cart"
import { txt } from '@/use/txt'
import {useQuery, useResult, useMutation} from '@vue/apollo-composable'
import { gql } from '@apollo/client'


export default {
    setup() {
        const { me, prefs, message, products } = useCart()

        const product = (id) => products.value.find(i => i.id == id)

        const { result, loading } = useQuery(gql`
            {
                reviews(order_by: [{column: "id", order: DESC}]) {
                    id
                    author
                    text
                    rating
                    product {
                        id
                    }
                }
            }
        `)

        const reviews = useResult(result, null, data => data.reviews)

        return { me, prefs, txt, reviews, loading, products, product }
    }
}
</script>

<style>
.reviews p { padding: 1em; }
.reviews p:nth-child(2n) { background: #fff; }
.reviews p:nth-child(2n+1) { background: #f8f8f8; }

</style>